<template>
  <div>


    <div class="container">
      <h1 class="align-content-center"> AI Eye-tracking </h1>

      <div class="p-4">

        <div class="row">
          <div class="col-3">
            <vs-slider max=255 step=1 v-model="th" />
            <br />
            <vs-slider max=100 step=1 v-model="alfa" />
          </div>
          <div class="col-2 align-items-center">
          </div>
          <div class="col-7 align-items-center">
            th = {{ th }} <br />
            alfa = {{ alfa }}
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <div class="form-group">
              <label>Weight Red</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="weight red :" v-model="w_r" />
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>Weight Yellow</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="weight yellow :" v-model="w_y" />
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label>Weight Green</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="weight green :" v-model="w_g" />
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>Threshold Red</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="threshold red :" v-model="th_r" />
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label>Threshold Yellow</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="threshold yellow :" v-model="th_y" />
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>Threshold Green</label>
              <div class="position-relative">
                <input type="text" class="form-control pl-2" placeholder="threshold green :" v-model="th_g" />
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-3">
            <vs-upload automatic class="text-info"
              :action="this.$hostnamejava + '/eyetracking/upload-v3'"
              fileName='file' :data="avatarForm" text='Загрузить файл' />
            <!--<img :src="getImage(0)" style="height:10%;" class="m-2">-->
            <img :src="getImageHash(hash_str)" style="height:10%;" class="m-2">
          </div>
          <div class="col-9">
            <div class="sample-div">

            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>

import store from "@/store/store";


let sseClient;


export default {
  name: "eyetracking.vue",
  data() {
    return {
      hash_str: '',
      th: 200, alfa: 50,
      w_r: 0.8, w_y: 0.6, w_g: 0.2, th_r: 240, th_y: 170, th_g: 100
    }
  },

  mounted() {
    if (this.$cookies.isKey('th')) {
      this.th = this.$cookies.get('th')
    }
    if (this.$cookies.isKey('alfa')) {
      this.alfa = this.$cookies.get('alfa')
    }

    if (this.$cookies.isKey('w_r')) {
      this.w_r = this.$cookies.get('w_r')
    } else {
      this.$cookies.set('w_r', this.w_r);
    }
    if (this.$cookies.isKey('w_y')) {
      this.w_y = this.$cookies.get('w_y')
    } else {
      this.$cookies.set('w_y', this.w_y);
    }
    if (this.$cookies.isKey('w_g')) {
      this.w_g = this.$cookies.get('w_g')
    } else {
      this.$cookies.set('w_g', this.w_g);
    }

    if (this.$cookies.isKey('th_r')) {
      this.th_r = this.$cookies.get('th_r')
    } else {
      this.$cookies.set('th_r', this.th_r);
    }
    if (this.$cookies.isKey('th_y')) {
      this.th_y = this.$cookies.get('th_y')
    } else {
      this.$cookies.set('th_y', this.th_y);
    }
    if (this.$cookies.isKey('th_g')) {
      this.th_g = this.$cookies.get('th_g')
    } else {
      this.$cookies.set('th_g', this.th_g);
    }

    let d3script = document.createElement('script');
    d3script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/d3/4.10.2/d3.js');
    document.head.appendChild(d3script);
    let togglescript = document.createElement('script');
    togglescript.setAttribute('src', 'button-toggle.js');
    document.head.appendChild(togglescript);
    let mainscript = document.createElement('script');
    mainscript.setAttribute('src', 'drawing-4-main-v4.js');
    document.head.appendChild(mainscript);

    this.hash_str = this.$cookies.get('eyetracking');

    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/eyetracking/sse-subscribe',
      format: 'json',
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on('message', this.handleMessage);

    sseClient.connect().then(sse => {
      console.log('We\'re connected!');
    })
      .catch((err) => {
        console.error('Failed to connect to server', err);
      });



  },
  watch: {
    th: function (val) {
      this.$cookies.set('th', val);
    },
    alfa: function (val) {
      this.$cookies.set('alfa', val);
    },
    w_r: function (val) {
      this.$cookies.set('w_r', val);
    },
    w_y: function (val) {
      this.$cookies.set('w_y', val);
    },
    w_g: function (val) {
      this.$cookies.set('w_g', val);
    },
    th_r: function (val) {
      this.$cookies.set('th_r', val);
    },
    th_y: function (val) {
      this.$cookies.set('th_y', val);
    },
    th_g: function (val) {
      this.$cookies.set('th_g', val);
    },
  },
  computed: {
    avatarForm: function () {
      console.log('inside avatarForm');
      return {
        user_id: store.state.user_id,
        th: this.th,
        alfa: this.alfa,
      }
    },
  },
  methods: {
    getImage(x) {
      return '' + this.$hostnamejava + '/eyetracking/get-image-out/' + x + '/' + store.state.user_id ;
    },
    getImageHash(x) {
      return '' + this.$hostnamejava + '/eyetracking/get-image/' + x;
    },

    handleMessage(message, lastEventId) {
      console.log(message)
      this.$cookies.set('eyetracking', '' + message.hash);
      if (message.status === 'done') {
        this.$vs.notify({
          time: 4000,
          title: 'Успех',
          text: 'файл загружен и обработан',
          color: 'success'
        });
        this.$router.go();
      }
    },
  },
  beforeDestroy() {
    sseClient.disconnect();
  },


}
</script>

<style scoped></style>